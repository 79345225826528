/* Media Queries
-------------------------------------------------------------- */
@media only screen and (max-width: 1890px) {
  .flat-tabs.tab-authors .react-tabs__tab-panel .col-item,
  .flat-tabs.items .react-tabs__tab-panel .col-item {
    width: calc(25% - 30px) !important;
  }
}
@media only screen and (max-width: 1815px) {
  .mode_switcher {
    right: 15px !important;
  }

  /* .question-form {
    width: 18%;
  } */
}
@media only screen and (max-width: 1700px) {
  .flat-search-btn {
    right: 80px !important;
  }
  .flat-tabs.items .react-tabs__tab-panel .content-item2 .col-item {
    width: 1510px !important;
  }
  .home-7 .mainslider .swiper-button-prev,
  .home-7 .mainslider .swiper-button-next {
    display: none;
  }
}

@media only screen and (max-width: 1600px) {
  .main-nav {
    left: 18%;
  }
  .main-nav > ul > li {
    padding: 26px 12px 26px 12px;
  }
}

@media only screen and (max-width: 1565px) {
  .flat-tabs.tab-authors .react-tabs__tab-panel .col-item,
  .flat-tabs.items .react-tabs__tab-panel .col-item {
    width: calc(33.33% - 30px) !important;
  }
  #side-bar.style-3,
  #side-bar.style-2 {
    padding: 0 0;
  }

  .tab-authors .menu-tab .tablinks {
    padding-left: 50px;
    padding-right: 50px;
  }

  .tab-authors .author-profile .widget-social,
  .form-list-item {
    padding-left: 0;
  }

  .tab-authors .author-profile .info-profile .content {
    padding-right: 0px;
  }

  .widget-social.style-3 ul li {
    margin-right: 8px;
  }

  .collection .swiper-button-prev.btn-slide-prev {
    left: 0;
  }

  .collection .swiper-button-next.btn-slide-next {
    right: 0;
  }
  .flat-title-page.style2 .img-bg,
  .uploadFile input[type="file"] {
    right: 15px !important;
  }
  /* .question-form, */
  .mode_switcher h6 {
    display: none;
  }
  .tf-item-details .content-right {
    padding-left: 0;
  }
  #side-bar.side-bar.style-2 {
    padding-left: 0;
  }
  .wrap-flex-box .post {
    padding-right: 2%;
  }
  .form-upload-profile {
    padding-left: 0;
  }
  .home-1 .sc-box-icon-inner {
    margin-left: -5px;
  }
  .mainslider .swiper-button-prev,
  .mainslider .swiper-button-next {
    display: none !important;
  }
}

@media only screen and (max-width: 1450px) {
  .right .mainslider.home .wrap-heading .content {
    padding-right: 15px;
  }
  .tf-item-details.style-2 .content-right .meta-item-details .countdown__value {
    width: 35px;
  }

  .sub-title.pad-400 {
    padding: 0 300px;
  }

  .tf-item-details.style-2
    .content-right
    .meta-item-details
    .js-countdown
    .countdown__label {
    margin: 0px 4.1px;
  }

  .tf-box .box-item {
    width: 25%;
    padding-left: 0 !important;
  }

  #footer .widget-social ul {
    flex-wrap: wrap;
  }

  .widget-social.style-1 ul li {
    margin-bottom: 10px;
  }
  .home-7 .today-pick .content-inner .sc-card-product {
    width: calc(25% - 26px);
  }
}
@media only screen and (max-width: 1430px) {
  .authors-2 .react-tabs__tab-list {
    padding: 0 2% 0 4.5%;
  }
  .tab-authors .author-profile {
    padding-bottom: 6.5%;
  }
}
@media only screen and (max-width: 1366px) {
  .swiper-button-next,
  .swiper-button-prev,
  .seller .swiper-button-next.btn-slide-next,
  .seller .swiper-button-prev.btn-slide-prev {
    display: none !important;
  }

  .header_2.question-form {
    display: none;
  }

  .sc-card-product .card-bottom .sc-button {
    padding: 10px;
  }

  .flat-form {
    margin: 0 10%;
  }

  .sc-card-product.explode .card-bottom .reload::before {
    font-size: 17px;
  }

  .sc-card-product.explode .price .price-details h5 {
    font-size: 15px;
  }

  .sc-card-product.explode .card-bottom .view-history {
    font-size: 14px;
  }

  #site-header #main-nav {
    left: 15%;
  }
  .box-activity .sc-card-activity.style-2 {
    width: 100%;
  }

  .table-ranking .item .column1 {
    width: 35%;
  }
  .table-ranking .th-title .column1 {
    width: 27%;
  }

  .tab-collections .collection-banner .collection-banner-contents {
    padding: 2rem;
  }

  .tab-collections .collection-banner .info-profile {
    flex-grow: 0.8;
  }
  .tab-collections .collection-banner .collection-stats .grid-container {
    grid-template-columns: 200px 200px;
  }

  .search-options-text {
    display: none;
  }

  .flat-tabs.items .react-tabs__tab-panel .option .view {
    flex-direction: column;
    gap: 0.5rem;
  }

  .flat-tabs.items .react-tabs__tab-panel .btn-sort-by {
    padding: 9px;
  }

  .flat-tabs.items .react-tabs__tab-panel .option .view ul,
  .flat-tabs.items .react-tabs__tab-panel .btn-sort-by svg {
    margin-right: 0;
  }

  .flat-tabs.items .react-tabs__tab-panel .option .dropdown {
    width: 110px;
  }

  .count-and-search {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 1199px) {
  .tf-item-details .content-right .meta-item-details .item {
    display: block;
  }
  .mainslider.home .flat-slider {
    padding: 0;
  }
  .right .mainslider.home .wrap-heading .content {
    padding-right: 30px;
  }
  .col-box-83 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .flat-tabs.tab-authors .react-tabs__tab-panel .col-item,
  .flat-tabs.items .react-tabs__tab-panel .col-item {
    width: calc(50% - 30px) !important;
  }
  .widget-subcribe {
    padding-left: 0;
  }

  .table-ranking .th-title,
  .table-ranking .item {
    width: 1200px;
  }

  .table-ranking .th-title .column1 {
    width: 30%;
  }
  .table-ranking .item .column.td2,
  .table-ranking .item .column.td3,
  .table-ranking .item .column.td4 {
    padding-left: 15px;
  }

  .table-ranking .item .column.td5 {
    padding-right: 30px;
  }

  #site-header .sc-button span {
    font-size: 0px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 22px;
  }

  #site-header .sc-button span::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .tab-authors .author-profile .widget-social {
    flex-direction: row-reverse;
  }

  .style-3 .sc-box-icon.no-box-shadown.mgbt-0 {
    display: none;
  }

  .themesflat-container {
    width: 1200px;
    max-width: 100%;
  }

  .tf-item-details .content-left .media img {
    width: 100% !important;
  }

  .content-tab .content-inner,
  .style-3 .sc-box-icon {
    width: calc(33.3% - 30px);
  }

  .box-epxlore .sc-card-product {
    width: 48%;
  }

  .sub-title.pad-400 {
    padding: 0 100px;
  }
  .form-upload-profile,
  .sub-title.pad-420,
  .box-feature-contact {
    padding: 0 0;
  }

  .tab-authors .menu-tab .tablinks {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tab-authors .menu-tab {
    padding-left: 0;
    padding-right: 0;
  }

  .widget-logo .sub-widget-logo {
    padding-right: 0;
  }

  .tf-item-details .content-right {
    padding-left: 0 !important;
  }

  .tab-authors .author-profile .widget-social ul {
    margin-left: 15px;
  }

  .inner-post .image-box,
  .widget-tag.fl-st-1 {
    margin-right: 0;
  }
  .tf-item-details .content-left {
    margin-bottom: 3rem;
  }

  .sc-card-collection .card-bottom .author {
    margin-bottom: 15px;
  }

  .sc-card-collection .card-media,
  .sc-card-collection .media-images-box {
    margin-top: 0px;
  }

  .tab-authors .author-profile .widget-social ul li {
    margin-bottom: 5px;
  }

  .inner-post .image-box img:last-child,
  .sc-box-icon.mg-bt {
    margin-bottom: 0;
  }

  .tab-authors .menu-tab {
    justify-content: center;
  }

  .inner-post .image-box img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .mainslider.home3 .image .img-bg {
    max-width: 50%;
    right: 15px;
  }

  .tablet-30 {
    margin-bottom: 30px !important;
  }
  .authors-2 .react-tabs__tab {
    padding: 2.2% 54px 2.5%;
  }
  .home-1 .sc-box-icon-inner {
    margin-left: -30px;
  }
  .col-box-17 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .side-bar.item {
    width: 27%;
  }

  .tab-collections .collection-banner .collection-stats .grid-container {
    grid-template-columns: 250px;
  }

  .tab-collections .collection-banner .info-profile {
    padding-left: 10px;
    height: 276px;
  }

  .tab-collections .collection-banner .widget-social {
    left: 10px;
    position: absolute;
    bottom: 10px;
  }
  .home-7 .today-pick .content-inner .sc-card-product {
    width: calc(33.33% - 26px);
  }
  .home-7 .tf-section.top-seller {
    padding: 76px 0 76px;
  }
  .home-7 .flat-title-page.style3 .mainslider.home .wrap-image {
    display: none;
  }
  .home-7 .flat-slider .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 4rem 0;
  }
  .home-7 .flat-slider .swiper {
    display: none;
  }
  .home-7 .sc-card-product {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .side-bar.item {
    width: 100%;
  }
  .flat-tabs.items .react-tabs__tab-panel {
    padding: 20px 15px 0px 15px;
  }
  .option h2 {
    font-size: 28px;
  }
  #side-bar.item .widget {
    padding: 15px 80px 15px 15px;
  }
  .tf-soft {
    flex-wrap: wrap;
  }
  .tf-soft .soft-left {
    margin-bottom: 20px;
  }
  .tf-section.live-auctions.style4.home5 {
    padding: 80px 0 40px;
  }
  /* .home-1 .flat-title-page {
    padding: 100px 0 90px;
  } */
  .home-8 .side-bar.item {
    position: relative;
    top: 0px;
  }
  .home-8 .flat-tabs.items .react-tabs__tab-list {
    position: relative;
    top: 0px;
  }

  .tf-my-items {
    padding: 100px 0 0;
  }

  .tf-game-details .flat-tabs.items .react-tabs__tab-list,
  .tf-my-items .flat-tabs.items .react-tabs__tab-list,
  .tf-new-profile .flat-tabs.items .react-tabs__tab-list {
    border: none;
    border-bottom: 1px solid rgba(235, 235, 235, 0.2);
    top: unset;
    width: 100%;
    overflow-y: hidden;
    white-space: nowrap;
    overflow-x: scroll;
    display: inline-block;
    /* margin-left: -15px; */
    background-color: unset;
    border-radius: unset;
    transition: none;
  }

  .my-items .flat-tabs.items .react-tabs__tab-panel {
    margin-top: 0;
    border: none;
  }

  .mainslider.home .wrap-heading .content {
    width: 100%;
  }
  .main-nav {
    margin-left: -15px !important;
  }
  .is_dark .main-nav.active {
    background-color: #14141f;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 20%);
  }

  .main-nav.active ul {
    flex-direction: column;
  }

  .main-nav.active ul li {
    margin: 0;
    text-align: left;
    cursor: pointer;
    padding: 0;
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .main-nav.active ul li:first-child {
    border-top: 0px;
  }

  .main-nav.active ul li > ul > li:first-child {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .main-nav.active ul > li.current-menu-item > a {
    color: var(--primary-color3);
  }

  .is_dark .main-nav.active ul > li.current-menu-item > a {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .main-nav > ul > li .sub-menu li.current-item a {
    padding-left: 15px !important;
  }
  .main-nav > ul > li .sub-menu li.current-item a:after {
    display: none;
  }
  .main-nav.active .current-item > a,
  .main-nav.active ul > li > a:hover {
    color: var(--primary-color3);
  }

  .is_dark .main-nav.active .current-item > a,
  .is_dark .main-nav.active ul > li > a:hover {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .sub-menu .menu-item a {
    margin-left: 25px;
  }

  .main-nav.active .menu-item-has-children .arrow {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-family: "FontAwesome";
    line-height: 48px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 0;
    width: 48px;
  }

  .sc-box-icon {
    margin-bottom: 40px;
  }
  .main-nav.active .menu-item-has-children .arrow:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    color: var(--primary-color2);
  }

  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    color: var(--primary-color3);
  }

  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main-nav.active .menu-item-has-children .arrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .main-nav.active .menu-item-has-children .arrow.active:before {
    content: "\f107";
  }

  .main-nav.active ul ul li {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }

  .is_dark .main-nav.active ul ul li {
    background-color: #14141f;
  }

  header .mobile-button,
  .tf-item-details.style-2 .content-right .meta-item-details,
  .tf-item-details.style-2 .content-right .meta-item {
    display: block !important;
  }

  .mode_switcher {
    top: 90px;
    display: block;
  }
  .flat-cart_item {
    padding: 180px 0 20px;
  }

  .flat-search-btn {
    right: 60px !important;
  }

  .mainslider.home3 .image .img-bg {
    top: 20%;
  }
  .mainslider.home3 .image {
    width: 100%;
  }

  .item-style-2 .item .js-countdown {
    margin: 0 auto;
  }
  .mainslider.home3 .image,
  .mainslider.home .image {
    margin-top: 50px !important;
  }

  .tf-item-details.style-2 .content-right .meta-item-details .countdown__value {
    width: 60px;
  }
  .flat-title-page.style2 .shape.item-w-22,
  .swiper-pagination-bullets,
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .content-left .media img,
  .tf-item-details.style-2 .content-right .meta-item-details .item-style-2,
  .tf-item-details.style-2 .meta-item .left,
  .tf-item-details.style-2 .meta-item .right,
  .wrap-flex-box .post,
  .wrap-flex-box .side-bar {
    width: 100%;
  }
  .sc-card-profile,
  .content-left .media {
    margin-bottom: 30px;
  }
  .authors .sc-author-box {
    padding-bottom: 20px;
  }

  .style-2 .sc-box-icon,
  .content-tab .content-inner {
    width: calc(50% - 30px);
  }

  .tf-box .box-item {
    width: 33.33%;
  }

  .tf-item-details .content-right .meta-item-details .item {
    padding: 12px;
  }
  .wrap-flex-box .post,
  .wrap-flex-box .side-bar,
  .widget-menu,
  .widget-subcribe {
    padding: 0 0 !important;
  }

  #footer.style-2,
  #footer {
    padding: 70px 0px !important;
  }

  .tf-section.live-auctions.style2 {
    padding-top: 15px;
  }

  section.today-picks {
    padding: 0px 0px 70px 0px;
  }

  .sub-title.pad-400,
  .uploadFile,
  .tab-list-item form input {
    padding: 0 15px;
  }

  .tab-authors .author-profile .feature-profile {
    padding-top: 22px;
  }

  .mainslider.home .image {
    padding-right: 0px !important;
  }

  .tf-item-details .content-right .themesflat-tabs .tab-title {
    margin-right: 10px !important;
  }

  .tf-item-details .content-right .themesflat-tabs .tab-content {
    padding-right: 10px;
  }

  .wrap-inner.load-more.mg-t-42,
  .sc-card-activity .button-active {
    margin: 0;
  }

  .flat-form {
    margin: 0 30px;
  }

  .header-search {
    margin-right: 30px !important;
  }

  .sc-card-activity .content .media {
    margin-right: 15px;
  }

  .wrap-flex-box .post {
    margin-bottom: 70px;
  }

  .sc-card-collection.style-3.mg-bt,
  .style-2 .sc-box-icon.mgbt-30,
  #footer .widget,
  .explode.style2.mg-bt,
  .sc-card-product.explode.style2.mg-bt,
  .sc-card-product {
    margin-bottom: 30px;
  }

  .auctions .live-auctions .sc-card-product {
    margin-bottom: 30px;
  }

  .widget-logo .logo-footer,
  .widget-menu .title-widget,
  .widget-subcribe .title-widget,
  .tf-item-details.style-2 .content-right .meta-item-details .item-style-2,
  .tf-item-details.style-2 .content-right .meta-item-details .item,
  .tf-item-details.style-2 .meta-item .left {
    margin-bottom: 15px;
  }

  #footer .widget.widget-subcribe,
  .sc-card-product.explode.style2 {
    margin-bottom: 0;
  }

  .tf-item-details.style-2 .meta-item .right {
    text-align: unset;
  }

  .sc-card-article .text-article h3 {
    font-size: 22px;
  }

  .flat-title-page.style2 .img-bg {
    top: 25%;
  }
  .flat-title-page.style-3,
  .is_dark .flat-title-page {
    border-bottom: 1px solid #343444;
  }

  .authors-2 .react-tabs__tab {
    padding: 2.2% 33px 2.5%;
  }
  .tf-section.tf-item-details {
    padding: 30px 0px 0px 0px;
  }
}

@media only screen and (max-width: 900px) {
  .tab-collections .collection-banner .collection-banner-contents {
    padding: 2rem;
    flex-direction: column;
  }
  .tab-collections .collection-banner .info-profile {
    position: absolute;
    right: 10px;
    width: calc(98% - 266px);
    height: 246px;
    overflow-x: hidden;
  }
  .tab-collections .collection-banner .widget-social {
    padding-right: 24px;
    position: relative;
  }
  .tab-collections .collection-banner .collection-stats .grid-container {
    width: 100%;
    grid-template-columns: 33% 33% 33%;
    border-left: none;
    padding-left: 0px;
  }

  .launchpad-tabs .react-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .launchpad-tabs .react-tabs .launchpad-tab {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }

  .launchpad-tabs .react-tabs .launchpad-tab .launchpad-details {
    width: 100%;
    /* text-align: center; */
    align-items: center;
  }
  .launchpad-heading {
    display: none;
  }
  .launchpad-section {
    padding: 20px 0;
  }
  .launchpad-info-container {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    text-align: center;
  }
  .launchpad-info-text {
    text-align: justify;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  .side-bar.style-3 .widget-category.border-bt {
    border: none;
  }
  .side-bar.style-3 {
    padding-top: 3px !important;
  }
  .box-epxlore {
    margin-top: 30px;
  }
  .widget-category .title-wg-category i {
    padding-top: 10px;
    padding-left: 5px;
  }
  .sc-card-product .card-bottom .sc-button {
    padding: 10px 25px;
  }
  .home-7 .today-pick .content-inner .sc-card-product {
    width: calc(50% - 26px);
  }
}
@media only screen and (max-width: 767px) {
  .form-info-profile,
  .form-upload-profile .option-profile,
  .tab-authors .author-profile .widget-social ul,
  .sc-widget.style-1,
  .meta-item-details.style2,
  #site-header-inner .wrap-box.flex,
  .wrap-flex-box .inner-content .meta-post,
  .widget-category .title-wg-category i,
  .tab-authors .author-profile {
    display: block !important;
  }
  .col-box-83,
  .col-box-17 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .side-bar.item {
    width: 100%;
    margin-top: 0;
  }
  .home-8 .side-bar.item {
    height: 100%;
  }

  .my-profile .side-bar.item {
    background-color: var(--bg-section);
  }

  .mobile-button {
    display: block !important;
  }

  .right .mainslider.home .wrap-heading .content {
    text-align: center;
    padding-right: 0;
  }
  .right .mainslider.home .wrap-heading .content .flat-bt-slider.style2 {
    justify-content: center;
  }
  /* .home-1 .flat-title-page {
    height: 440px;
  } */
  .authors-2 .react-tabs__tab {
    padding: 4.2% 35px 4.5%;
    width: 100%;
    text-align: center;
  }
  .explore .select-box .dropdown ul {
    bottom: 47px;
  }
  .mainslider.home .wrap-heading .content {
    padding-top: 70px;
  }
  .tf-section.brow-category {
    padding: 80px 0;
  }
  .tf-section.category,
  .tf-section.live-auctions {
    padding: 20px 0 0;
  }
  .top-seller.home5 .react-tabs__tab-list {
    top: 60px;
    right: auto;
    left: 15px;
  }
  .tf-section.top-seller.home5 .react-tabs .content-tab {
    margin-top: 60px;
  }
  .top-seller.home5 .box-item {
    width: 100%;
  }
  .tf-soft .soft-left {
    flex-wrap: wrap;
  }
  .tf-soft .soft-left .dropdown {
    margin-bottom: 10px;
  }
  .sc-card-product .card-bottom .sc-button {
    padding: 10px;
  }
  .wrap-cart {
    flex-wrap: wrap;
  }
  .wrap-cart .cart_item.item1 {
    width: calc(31.8% - 30px);
  }

  .wrap-cart .cart_item {
    width: 100%;
  }

  .wrap-cart .cart_item.style2 {
    width: 100%;
  }

  .tf-box-icon.tf-section.home3 {
    padding-top: 70px;
  }
  .home-7 .sc-card-collection.style-2 {
    margin-bottom: 30px;
  }
  .home-7 .tf-box-icon.tf-section.live-auctions.style7 {
    padding: 81px 0 80px;
  }
  .home-7 .today-pick .content-inner .sc-card-product {
    width: 100%;
  }
  .home-7 .sc-box-icon-inner.style3 .sc-box-icon {
    width: 100%;
  }
  .home-7 .sc-card-product {
    margin-bottom: 0;
  }
  .home-7 .top-seller.home5 .react-tabs__tab-list {
    left: auto;
    top: 60px;
  }
  .home-7 .flat-slider {
    padding-top: 0;
  }
  .modal {
    padding: 0 10px;
  }
  .accordion-item .accordion-button::after {
    right: 0;
  }
  .authors .sc-author-box {
    padding-bottom: 0;
  }
  .tf-section.live-auctions.style2,
  .tf-section,
  .tf-section.our-creater,
  .tf-section.sc-explore-1,
  .flat-title-page,
  .tf-section.tf-explore-2,
  .flat-title-page.style2,
  .flat-title-page.style-3,
  .tf-box-icon.tf-section,
  section.tf-section.live-auctions.home3,
  .tf-section.live-auctions.style1,
  .tf-section.top-seller,
  .tf-section.top-seller.home3.s2,
  .tf-section.live-auctions.pad-b-74 {
    padding: 70px 0px;
  }
  .tf-my-items {
    padding: 100px 0px;
  }
  .launchpad-section {
    padding: 20px 0;
  }
  .launchpad-tabs .react-tabs__tab {
    margin-right: 20px !important;
  }
  .home-1 .tf-section.live-auctions {
    padding: 38px 0 30px;
  }
  .home-1 .tf-section.top-seller {
    padding: 60px 0px 0px;
  }
  .home-1 .tf-section.popular-collections {
    padding: 38px 0 30px;
  }
  .home-1 .tf-section.top-collections {
    padding: 38px 0 30px;
  }
  .home-1 .tf-box-icon.create {
    padding-top: 0;
  }
  .home-1 .wrap-cart .cart_item .inner-cart {
    margin-bottom: 0;
  }

  .tf-section.tl-pb-0 {
    padding-bottom: 70px !important;
  }

  .form-info-profile .info-account,
  .form-info-profile .info-social {
    width: calc(100% - 0px);
    margin-left: 0px;
  }

  .form-info-profile {
    margin-left: 0;
  }
  #side-bar.style-3 {
    padding: 0 !important;
    overflow-y: scroll;
    overscroll-behavior: contain;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
  }

  .form-upload-profile .option-profile form {
    height: 102px;
    margin-bottom: 30px;
    padding: 0 !important;
  }

  .option-profile .uploadFile input[type="file"] {
    display: block !important;
  }

  .tab-authors .author-profile .widget-social .btn-profile,
  .widget-social.style-3 ul li a {
    margin-left: auto;
  }
  .list-item .react-tabs__tab-list {
    flex-wrap: wrap;
  }
  .list-item .react-tabs__tab {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .no-pt-mb {
    padding-top: 0 !important;
  }

  #side-bar.style-2 {
    margin-top: 50px;
  }

  #side-bar.item .wrap-category {
    height: fit-content;
  }

  .sc-card-product .card-bottom .view-history {
    font-size: 14px;
  }

  .mainslider .flat-slider .heading {
    font-size: 72px;
  }

  .select-box.box-right {
    text-align: left;
  }

  .mb-pb-30 {
    padding-bottom: 30px !important;
  }

  .mainslider .image,
  .flat-title-page .shape {
    display: none !important;
  }

  .flat-title-page {
    padding-top: 95px;
  }

  .flat-title-page.style-3 {
    padding-top: 95px !important;
    padding-bottom: 60px !important;
  }

  .flat-title-page.style2 {
    padding-top: 60px !important;
    padding-bottom: 60px;
  }

  #header_main {
    height: auto !important;
  }

  .flat-title-page.style2 .shape.item-w-68,
  .flat-title-page.style2 .img-bg,
  .box-title-login::before,
  .box-title-login::after {
    display: none;
  }

  .tab-authors .author-profile .avatar {
    position: relative;
  }

  .tab-authors .author-profile .avatar {
    max-width: 50%;
  }

  #comments fieldset.name,
  #comments fieldset.email,
  .form-upload-profile .option-profile form,
  .mainslider.home3 .wrap-heading.flat-slider,
  .client-info.sc-card-product .meta-info,
  .meta-item-details .item.meta-price,
  .meta-item-details .item.count-down,
  .tab-authors .author-profile .feature-profile,
  .tab-authors .author-profile .info-profile,
  .box-epxlore .sc-card-product,
  .mainslider.home .image,
  .mainslider.home .wrap-heading .content,
  .box-activity .sc-card-activity.style-2,
  .select-box,
  .meta-post .box,
  .box-feature-contact img {
    width: 100% !important;
  }

  .sc-card-collection.style-2 .media-images-collection .box-left,
  .sc-card-collection.style-2 .media-images-collection .box-right,
  .tf-box .box-item {
    width: 50%;
  }

  .themesflat-container {
    width: 750px;
  }

  .style-2 .sc-box-icon,
  .content-tab .content-inner {
    width: calc(100% - 30px);
  }

  .style-3 .sc-box-icon {
    width: calc(50% - 30px);
  }
  .flat-form .pad-0-15,
  #comments fieldset.name,
  #comments fieldset.email,
  .tab-authors .author-profile .feature-profile,
  .tab-authors .author-profile .widget-social {
    padding: 0 0;
  }

  .tab-authors .author-profile {
    padding: 30px 15px;
  }

  .tab-authors .menu-tab .tablinks {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tab-authors .author-profile .info-profile {
    padding-left: 0;
  }

  .tab-authors .menu-tab .tablinks {
    padding-right: 20px;
  }

  .load-more.mg-t-9,
  .flat-form,
  .sc-card-activity .button-active {
    margin: 0;
  }
  .form-info-profile .info-account,
  .box-feature-contact,
  .sub-title.style-1 {
    margin-bottom: 50px !important;
  }

  .widget-social.style-3 ul li {
    margin-right: 0 !important;
  }
  .sc-box-icon.mg-bt,
  .our-creater .sc-card-collection.style-2.mg-bt,
  .our-creater .sc-card-collection.style-2 {
    margin-bottom: 30px;
  }
  .client-info.sc-card-product .meta-info,
  .wrap-box.explore-1 .dropdown,
  .meta-item-details .item.meta-price,
  .style-2 .sc-box-icon {
    margin-bottom: 20px !important;
  }

  .tab-authors .author-profile .widget-social .btn-profile,
  .widget.widget-tag.style-2,
  .meta-post .box:first-child {
    margin-bottom: 15px;
  }

  .explore-tab .menu-tab li {
    margin-bottom: 10px;
  }
  .mainslider.home .flat-slider.flex,
  .wrap-box.explore-1 {
    flex-direction: column;
  }

  .meta-post .box.left {
    justify-content: unset;
  }

  .mainslider.home .swiper-slide-active .image {
    text-align: center;
  }

  .sc-card-activity .content .info h3 {
    font-size: 20px;
  }

  .sc-card-activity .button-active.icon {
    font-size: 17px;
  }

  .post .title-post {
    line-height: 35px;
    font-size: 28px;
  }

  .sc-card-activity .button-active {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .tab-authors .author-profile .widget-social {
    position: absolute;
    top: 30px;
    right: 15px;
    display: flex;
    flex-direction: column-reverse;
  }

  .pad-b-74.mobie-style,
  .tf-section.mobie-style {
    padding-bottom: 40px !important;
  }

  .pad-b-54.mobie-pb-70 {
    padding-bottom: 70px !important;
  }

  .tf-section.top-seller.home3.mobie-style {
    padding-top: 40px !important;
  }

  .tf-section.top-seller.home3 {
    padding-top: 70px;
  }

  .tab-authors .author-profile {
    padding: 20px;
  }
  .tab-authors .menu-tab .tablinks {
    font-size: 15px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .select-box {
    flex-wrap: wrap;
  }
  .select-box.box-right {
    justify-content: start;
  }
  .tf-item-details .content-right .themesflat-tabs .react-tabs__tab-panel {
    padding-right: 10px;
  }
  #side-bar.style-2 {
    padding-left: 0 !important;
  }

  .flat-accordion2 .accordion-item {
    padding: 20px 30px 4.5px 30px;
  }
  .authors-2 .react-tabs__tab.react-tabs__tab--selected::after {
    display: none;
  }

  .tab-collections .collection-banner .info-profile {
    position: relative;
    width: 100%;
    max-height: 446px;
    height: fit-content;
  }
  #side-bar.active {
    transform: translateX(0%);
  }
}
@media only screen and (max-width: 575px) {
  .tf-activity.s1 .sc-card-activity .content {
    flex-wrap: wrap;
  }
  .flat-title-page.inner .page-title-heading .heading {
    font-size: 35px;
  }
  .flat-tabs.items .react-tabs__tab-panel .content-item {
    margin: 0 !important;
  }
  .flat-tabs.tab-authors .react-tabs__tab-panel .col-item,
  .flat-tabs.items .react-tabs__tab-panel .col-item {
    width: calc(100%) !important;
    margin: 0 !important;
  }

  .flat-tabs.items .react-tabs__tab-panel .option {
    flex-direction: column;
  }

  .flat-tabs.items .react-tabs__tab-panel .option .view {
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }

  .flat-tabs.items .react-tabs__tab-panel .option .dropdown {
    width: 100%;
  }

  .home-6 .flat-cart-item .swiper {
    padding: 0;
  }

  .home-6 .flat-cart-item {
    padding: 120px 0 0 !important;
  }

  .home-6 .sc-card-collection {
    margin-bottom: 20px !important;
  }

  .item-count,
  .flat-tabs.items .react-tabs__tab-panel .option .view ul {
    display: none;
  }

  .refresh-button {
    margin-right: 0;
  }

  .search-options-text {
    display: unset;
  }

  .flat-tabs.items .react-tabs__tab-panel .btn-sort-by svg {
    margin-right: 10px;
  }

  .mainslider .flat-slider .heading {
    font-size: 52px;
  }
  .flat-slider .sub-heading {
    font-size: 20px;
  }
  .tf-title {
    font-size: 24px;
  }
  .flat-bt-slider .sc-button {
    padding: 10px 16px;
  }
}

@media only screen and (max-width: 549px) {
  .tab-list-item .tabs,
  .row-form.style-3 {
    display: block;
  }

  .wrap-cart .cart_item {
    width: calc(100% - 30px) !important;
  }

  .flat-search-btn {
    right: 55px !important;
  }

  .header-search {
    margin-right: 10px !important;
  }

  .uploadFile input[type="file"],
  .tab-authors .menu-tab .tablinks.active::after {
    display: none;
  }

  .tab-list-item .tablinks,
  .row-form.style-3 .inner-row-form {
    width: 100%;
  }

  .themesflat-container {
    width: 750px;
  }

  .style-3 .sc-box-icon {
    width: calc(100% - 30px);
  }

  .widget-filter a.box-widget-filter {
    padding: 0 15px;
  }

  .tab-authors .menu-tab {
    padding: 15px;
  }

  .row-form.style-3 .select-box {
    padding-top: 0;
  }

  .style-3 .sc-box-icon {
    margin-bottom: 30px !important;
  }

  .tab-list-item .tablinks,
  .row-form.style-3 .inner-row-form,
  .tab-list-item form input {
    margin-bottom: 30px;
  }

  .tab-list-item .tablinks:last-child,
  .row-form.style-3 .inner-row-form:last-child {
    margin-bottom: 0;
  }

  .tab-authors .menu-tab .tablinks {
    text-align: center;
  }

  .flat-slider .heading .style {
    font-size: 50px;
  }

  .sc-card-activity .content .info {
    margin-top: 15px;
  }

  .flat-accordion2 .flat-toggle2 h6.toggle-title {
    padding-right: 20px;
  }
  .flat-accordion2 .flat-toggle2 {
    padding: 24px 26px 5.5px 26px;
  }
  .sc-card-activity.style-2 .content .media {
    width: 140px;
  }
  .tab-collections .collection-banner .collection-stats .grid-container {
    grid-template-columns: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .sc-card-activity.style-2 .content .media,
  .sc-card-activity.style-2 .content .media img,
  .sc-card-activity .content .media {
    height: auto;
  }

  .sc-card-activity .content .media img,
  .tf-box .box-item {
    width: 100% !important;
  }

  .sc-card-activity .button-active.icon {
    margin: 10px auto 0;
  }
  .tab-authors .menu-tab .tablinks {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* HOME 6 */
.home-6 .tf-section.top-seller.home5 {
  padding: 80px 0 40px;
}
.home-6 .flat-cart-item {
  padding: 190px 0 73px;
}
.home-6 .live-auctions .sc-card-product {
  margin-bottom: 0;
}
.home-6 .flat-cart-item {
  padding: 190px 0 45px;
}
.home-6 .live-auctions .sc-card-product {
  margin-bottom: 0;
}
.home-6 .sc-card-collection {
  margin-bottom: 50px;
}
.home-6 .leaderboard {
  padding: 40px 0;
}
